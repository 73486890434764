<template>
  <div class="card p-2" style="min-height: calc(100vh  - 185px);">
    <div class="d-flex flex-wrap justify-content-between align-item-center">
      <h3>Pending Money Receipt</h3>
      <button
          @click="navigateToListPage()"
          class="btn btn-close"
      >
      </button>
    </div>
    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="onClickGo"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
          <label class="form-label">Sales Person</label>
          <AsyncSelect
              placeholder="Select Sales Person"
              v-model="singleSR"
              :api-service="fetchAllSR"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Customer: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
          </div>
          <AsyncSelect
              placeholder="Select Customer"
              v-model="contactProfile"
              :api-service="fetchContactProfiles"
              :additional-query="additionalContactOption"
              :format-label="formatPatientLabel"
              :additional-option="additionalOption"
              :key="contactRenderKey"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
          <label class="form-label">Order Type</label>
          <v-select
              placeholder="Select Business"
              v-model="saleType"
              :options="orderTypes"
              label="name"
              :reduce="name => name.value"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Bill type: </label>
          <v-select
              multiple
              placeholder="Select Bill Type"
              v-model="billType"
              :options="billTypeList"
              label="label"
              :reduce="label => label.value"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
          <label class="form-label">Business Unit</label>
          <v-select
              placeholder="Select Business"
              v-model="businessUnit"
              :options="business"
              label="name"
              :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
          <label class="form-label">Select Payment Status</label>
          <v-select
              placeholder="Select Payment Status"
              v-model="selectedPaymentStatus"
              :options="paymentStatus"
              label="name"
              :reduce="option => option.value"
          />
        </div>

      </DateQuerySetter>
    </div>
    <div class="col-12 px-2 mt-2 mb-4">
      <ListTable
          :tableItems="tableItems"
          :currentPage="invoiceData && invoiceData.data && invoiceData.data.current_page"
          :lastPage="invoiceData && invoiceData.data &&  invoiceData.data.last_page"
          :totalValue="invoiceData && invoiceData.total"
      />
    </div>
    <div class="px-2 position-absolute bottom-0 mt-4">
      <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <hr>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/pending-money-receipt/ListTable.vue'
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleCrm from '@/services/modules/crm/crm.js'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleInventory from "@/services/modules/inventory";
import Loader from '@/components/atom/LoaderComponent'
const {fetchHome} = handleInventory()

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
let business = ref([])
const params = reactive({
  searchTxt: '',
  offset: 10
});
const billTypeList = ref([])
const billType = ref(null)

const contactProfile = ref(null)
const singleSR = ref(null);
const singleDealer = ref(null);
const area = ref(null);
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);
const saleType = ref(null);
const selectedInvoiceStatus = ref(null);
const businessUnit = ref(null);
const invoiceStatuses = ref([]);
const invoiceData = ref( {});
const selectedPaymentStatus = ref(null);
const paymentStatus = ref([]);

const additionalOption = ref([]);
const additionalDealerQuery = ref({});
const additionalContactOption = ref({
  with_mobile: 1,
  dealer_id: ''
});
const orderTypes = ref([]);

const { fetchAllDealers, fetchAllSR, fetchAreaList } = handleCrm();
const { fetchContactProfiles } = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchSalesRegisterList, fetchSalesOrderTypes, fetchOrderStatus } = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const start = computed(() => route.query.start);
const end = computed(() => route.query.end);

const orderNumber = ref('');
const invoiceNumber = ref('');

watch(() => singleSR.value, (newValue, oldValue) => {
  additionalDealerQuery.value = {};
  additionalContactOption.value.dealer_id = '';
  singleDealer.value = null;
  contactProfile.value = null;
  dealerRenderKey.value += 1;
  contactRenderKey.value += 1;

  if(newValue) {
    additionalDealerQuery.value = {'sale_representative_id': newValue.id}
  }
})

watch(() => singleDealer.value, (newValue) => {
  additionalContactOption.value.dealer_id = '';
  contactProfile.value = null;
  contactRenderKey.value += 1;

  if(newValue) {
    additionalContactOption.value.dealer_id = newValue.id
  }
})

const onClickToExport = () => {
  let token = localStorage.getItem('token');
  let url = `${process.env.VUE_APP_BASE_URL}/export/inventory/sales-register${getQuery()}&_token=${token}&is_export=1`;
  let a = document.createElement("a")
  a.setAttribute("href", url)
  a.click()
}

const onOpenContactSearchModal = () => {
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  contactProfile.value = contact
}

const onClickGo = () => {
  getSaleRegisterList()
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

const getOrderStatus = async () => {
  const res = await fetchOrderStatus(`?company_id=${route.params.companyId}`);

  if (res.status) {
    invoiceStatuses.value = res.data.filter(item => ['created', 'pending'].includes(item.value))
  }
}

const getBusinessList = async () => {
  const res = await fetchBusinessList(`?company_id=${route.params.companyId}`);

  if (res.status) {
    business.value = res.data
  }
}

async function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  await router.push({path: route.path, query: routeQuery})
  await getSaleRegisterList();
}

const getSaleRegisterList = async () => {

  const query = getQuery();
  loading.value = true

  await fetchSalesRegisterList(query).then(res => {
    loading.value = false
    if (!res.status){
      tableItems.value = []
      invoiceData.value = {}
      store.commit("resetPagination")
      return
    }
    console.log("res",res)
    tableItems.value = res.data.data;
    invoiceData.value = res
    setPagination(res.data)
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const getSaleOrderTypes = async () => {
  const res = await fetchSalesOrderTypes(`?company_id=${route.params.companyId}`);
  if (res.status) {
    orderTypes.value = res.data
  }
}

const getQuery = () => {
  let query = `?company_id=${route.params.companyId}`;
  if (start.value && end.value) query += `&start_date=${start.value}&end_date=${end.value}`
  if (route.query.page) query += '&page=' + route.query.page
  if(singleSR.value) query += `&sale_representative_id=${singleSR.value.id}`;
  if(singleDealer.value) query += `&dealer_id=${singleDealer.value.id}`;
  if(contactProfile.value) query += `&contact_profile_id=${contactProfile.value.id}`;
  if(area.value) query += `&area_id=${area.value.id}`;
  if(orderNumber.value) query += `&order_number=${orderNumber.value}`;
  if(invoiceNumber.value) query += `&invoice_number=${invoiceNumber.value}`;
  if(saleType.value) query += `&sale_type=${saleType.value}`
  if(selectedInvoiceStatus.value) query += '&invoice_status='+selectedInvoiceStatus.value
  if(selectedPaymentStatus.value) query += '&payment_status='+selectedPaymentStatus.value
  if(businessUnit.value) query += '&business_unit='+businessUnit.value
  if (!!billType.value && billType.value.length > 0) {
    query += '&bill_type=' + billType.value
  }
  query += '&offset=' + params.offset
  return query;
}

function navigateToListPage() {
  const query = Object.assign({}, route.query)
  delete query.visit_client_id;
  delete query.type;

  router.push({
    name:route.query.type === 'approval'
        ? 'sales-order-approval'
        : (route.query.type === 'order-sales'
            ? 'order-sales'
            : 'sales-order-list'),
    params: route.params, query: query
  })
}
const getPaymentStatus = () => {
  let paymentStatusValue = [
    {name: 'Due', value: 'due'},
    {name: 'Paid', value: 'paid'}
  ]
  paymentStatus.value = paymentStatusValue
  selectedPaymentStatus.value = paymentStatusValue[0].value
}

onMounted(() => {
  getSaleOrderTypes()
  getOrderStatus()
  getBusinessList()
  getPaymentStatus()
  getSaleRegisterList()
  fetchHome().then(res => {
    if (res.data) {
      for (const value of Object.keys(res.data.hospital_invoice_types)) {
        billTypeList.value.push({
          value: value,
          label: res.data.hospital_invoice_types[value]
        })
      }
    }
  })
})
</script>
