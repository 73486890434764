<template>
  <div class="card card-height">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Invoice Number</th>
          <th>Invoice Type</th>
          <th>Order Type</th>
          <th>Client Name</th>
          <th>Business Unit</th>
          <th>Sales Person</th>
          <th>Invoice Amount</th>
          <th>VAT Amount</th>
          <th>Total Amount</th>
          <th>Discount Amount</th>
          <th>Paid Amount</th>
          <th>Due Amount</th>
          <th>Payment Status</th>
          <th>Action</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>{{ item.date }} </td>
          <td>{{ item.bill_number }}</td>
          <td>{{ item.invoice_type }}</td>
          <td>{{ item.sales_order_master ? item.sales_order_master.sale_type_title : '' }}</td>
          <td>{{ item.contact_name }}</td>
          <td>{{ item.business_name }}</td>
          <td>{{ item.sr_name }}</td>
          <td>{{ item.subtotal_amount }}</td>
          <td>{{ item.vat_amount }}</td>
          <td>{{ item.total_amount }}</td>
          <td>{{ item.discount }}</td>
          <td>{{ item.paid_amount }}</td>
          <td>{{ getTotalDue(item) }}</td>
          <td>{{ item.payment_status }}</td>
          <td>
            <button @click="onClickAddNewButton(item)" title v-if="item.payment_status === 'DUE'" class="btn btn-outline-primary btn-sm  ">
              <i class="fas fa-plus"></i>
              Money Receipt
            </button>
          </td>
        </tr>
        <tr v-if="currentPage === lastPage">
          <td colspan="7"></td>
          <td> {{ totalValue && totalValue.total_subtotal_amount }}</td>
          <td> {{ totalValue && totalValue.total_vat_amount }}</td>
          <td> {{ totalValue && totalValue.total_sum_amount }}</td>
          <td> {{ totalValue && totalValue.total_discount_amount }}</td>
          <td> {{ totalValue && totalValue.total_paid_amount }}</td>
          <td> {{ totalValue && totalValue.total_due_amount }}</td>
          <td> </td>
          <td> </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const props = defineProps({
  tableItems: {
    type: Array,
  },
  currentPage: Number,
  lastPage: Number,
  totalValue: Object,
})
const route = useRoute()
const router = useRouter()
const onClickAddNewButton = (item) =>  {
  let query = {
   contactId:item.contact_profile_id,
   type:'pending-money-receipt',
   start:route.query.start,
   end:route.query.end,
   page:route.query.page,
  }
  return router.push({name: 'sales-invoice-receipt', params: route.params, query: query})
}

const getTotalDue = (item) => {
  return parseFloat(item.total_amount) - parseFloat(item.net_receipt_amount) - parseFloat(item.ait_deducted) - parseFloat(item.vat_deducted) - parseFloat(item.discount)
}

</script>

<style scoped>
.mr-5 {
  margin-right: 5px;
}
.action-th {
  width: 20%;
  text-align: center;
}
.name {
  width: 15%;
}
.action{
  text-align: center;
}

</style>